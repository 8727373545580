














































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomerList, { ICustomerList_Item } from '../modules/Customer/List';

@Component
export default class CustomerSelect extends Vue {
  @Prop() readonly value?:ICustomerList_Item|null
  @Prop(Boolean) readonly disabled?:boolean
  @Prop(String) readonly invalidFeedback?:string

  isBusy = false;

  searchQuery = '';

  perPage = 10;
  currentPage = 1;

  customers = new CustomerList();

  fields = [
    { key: 'Number',        label: this.$t('Number')                                      },
    { key: 'Name',          label: this.$t('Name')                                        },
    { key: 'PostalArea',    label: this.$t('Postal area')                                 },
    { key: 'OverdueAmount', label: this.$t('Overdue amount'), class: 'display-priority-2' },
  ];

  async fetchCustomers() {
    this.isBusy = true;
    this.customers = await CustomerList.Fetch(this.currentPage, this.perPage, this.searchQuery);
    this.isBusy = false;
  }

  resetPageAndReloadCustomers() {
    this.pageSelected(1);
  }

  pageSelected(page: number) {
    this.currentPage = page;
    this.fetchCustomers();
  }

  customerSelected(record: ICustomerList_Item, idx: number) {
    (this as any).$bvModal.hide('select-modal');
    this.$emit('input', record);
  }
}
